import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";

function ToolsList(props) {
  const { workCenterDispatch, selectedOperation } = useStoreState(
    (state) => state
  );

  const { setToolListModalVisibleAction } = useStoreActions(
    (actions) => actions
  );

  const list = workCenterDispatch.filter(
    (wcd) =>
      wcd.OperationID === selectedOperation?.OperationID &&
      (wcd.ToolID || wcd.ToolDesc || wcd.ToolQtyReq || wcd.ToolTotalActUses)
  );

  return (
    <List
      tableHead={[
        "Tool ID",
        "Description",
        "Qty Required",
        "Total Actual Uses",
      ]}
      onPressCellEvents={Array(4).fill(
        () => () => setToolListModalVisibleAction(true)
      )}
      list={list}
      tableRows={list.map((wcd) => [
        wcd.ToolID,
        wcd.ToolDesc,
        wcd.ToolQtyReq ? parseFloat(wcd.ToolQtyReq).toFixed(2) : "",
        wcd.ToolTotalActUses ? parseFloat(wcd.ToolTotalActUses).toFixed(2) : "",
      ])}
      {...props}
    />
  );
}
export default ToolsList;

import React from "react";
import { Dimensions, View } from "react-native";
import WorkCenterCard from "../containers/Card/WorkCenterCard";
import DispatchCard from "../containers/Card/DispatchCard";
import OperationsCard from "../containers/Card/OperationsCard";
import MaterialCard from "../containers/Card/MaterialCard";
import Page from "../presentational/Page";
import BottomButtonGroup from "../containers/ButtonGroup/BottomButtonGroup";
import TaskModal from "../containers/Modal/TaskModal";
import LaborModal from "../containers/Modal/LaborModal";
import MoveModal from "../containers/Modal/MoveModal";
import LaborNotesModal from "../containers/Modal/LaborNotesModal";
import OperationListModal from "../containers/Modal/OperationListModal";
import MaterialListModal from "../containers/Modal/MaterialListModal";
import DispatchListModal from "../containers/Modal/DispatchListModal";
import ClockOutConfirmationModal from "../containers/Modal/ClockOutConfirmationModal";
import ProductionOrderNotesModal from "../containers/Modal/ProductionOrderNotesModal";
import Col from "../presentational/Col";
import styled from "styled-components/native";
import ToolListModal from "../containers/Modal/ToolListModal";
import StepListModal from "../containers/Modal/StepListModal";

const Container = styled(View)`
  padding: 1vh 2vw;
`;

const Home = ({ navigation }) => {
  return (
    <Page
      style={{
        backgroundColor: "#D2D4D7",
        height: Dimensions.get("window").height,
      }}
    >
      <Container>
        <TaskModal />
        <LaborModal />
        <MoveModal />
        <LaborNotesModal />
        <OperationListModal />
        <MaterialListModal />
        <ToolListModal />
        <StepListModal />
        <DispatchListModal />
        <ClockOutConfirmationModal />
        <ProductionOrderNotesModal />

        <Col>
          <WorkCenterCard />
        </Col>

        <Col>
          <DispatchCard />
        </Col>

        <Col>
          <OperationsCard />
        </Col>

        <Col>
          <MaterialCard />
        </Col>

        <BottomButtonGroup navigation={navigation} />
      </Container>
    </Page>
  );
};

export default Home;

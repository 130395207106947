import moment from "moment";
import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";
import Text from "../../presentational/Text";
import { useResponsiveTableSize } from "../../../hooks/responsive";
import { useRef, useEffect } from "react";

function ProductionOrderList(props) {
  const {
    workCenterDispatch,
    dispatchLoading,
    selectedDispatch,
    productionOrderSearchInput,
    currentDispatchObject,
  } = useStoreState((state) => state);

  const {
    selectDispatchThunk,
    setCurrentDispatchObject,
    setDispatchListModalVisibleAction,
  } = useStoreActions((actions) => actions);

  const { responsiveTableBodySize } = useResponsiveTableSize();

  const scrollViewRef = useRef(null);
  const itemRefs = useRef({});

  const Element = ({ data, dispatch }) => (
    <Text
      style={{
        fontSize: responsiveTableBodySize + 2,
        textAlign: "center",
        alignSelf: "center",
        color: "blue",
        width: "100%",
        height: "100%",
      }}
    >
      {data}
    </Text>
  );

  const productionOrderList = workCenterDispatch.reduce(
    (uniqueProductionOrders, item) => {
      const formattedSearchInput = productionOrderSearchInput
        .trim()
        .toLowerCase();
      const isNew = !uniqueProductionOrders.find(
        (wcd) => wcd.ProductionNbr === item.ProductionNbr
      );
      isNew &&
        (item.ProductionNbr.trim()
          .toLowerCase()
          .includes(formattedSearchInput) ||
          item.ProductionOrderDesc.trim()
            .toLowerCase()
            .includes(formattedSearchInput) ||
          item.InventoryID.trim()
            .toLowerCase()
            .includes(formattedSearchInput)) &&
        uniqueProductionOrders.push(item);
      return uniqueProductionOrders;
    },
    []
  );

  useEffect(() => {
    // Function to calculate the cumulative height dynamically
    const calculateHeightUntilChosenItem = async () => {
      const targetIndex = productionOrderList.findIndex(
        (item) => item.ProductionNbr === currentDispatchObject.ProductionNbr
      );

      if (targetIndex !== -1) {
        let totalHeight = 0;

        // Loop through each item up to the targetIndex
        for (let i = 0; i < targetIndex; i++) {
          const itemRef =
            itemRefs.current[productionOrderList[i].ProductionNbr];

          if (itemRef) {
            await new Promise((resolve) => {
              itemRef.measure((x, y, width, height) => {
                totalHeight += height; // Add each item's height to the total
                resolve();
              });
            });
          }
        }

        // Scroll to the cumulative height
        scrollViewRef.current?.scrollTo({
          y: totalHeight,
          animated: true,
        });
      }
    };

    // Run the calculation after rendering
    calculateHeightUntilChosenItem();
  }, [currentDispatchObject.ProductionNbr]);

  return (
    <List
      itemRef={(dispatch) => (ref) =>
        (itemRefs.current[dispatch.ProductionNbr] = ref)}
      scrollViewRef={scrollViewRef}
      doNotScroll={false}
      list={productionOrderList}
      onSelectRowInPopup={(dispatch) => () => {
        selectDispatchThunk(dispatch.ProductionNbr);
        setCurrentDispatchObject(dispatch);
        setDispatchListModalVisibleAction(false);
      }}
      onPressCellEvents={Array(8)
        .fill(false)
        .map((_, index) => (dispatch) => () => {
          if (index === 0) {
            selectDispatchThunk(dispatch.ProductionNbr);
            setCurrentDispatchObject(dispatch);
            setDispatchListModalVisibleAction(false);
          } else {
            setDispatchListModalVisibleAction(true);
          }
        })}
      selectItem={
        selectedDispatch == ""
          ? -1
          : productionOrderList.findIndex(
              (item) => item.ProductionNbr === selectedDispatch
            )
      }
      loading={dispatchLoading}
      width={["9%", "17%", "18%", "23%", "8%", "9%", "8%", "8%"]}
      tableHead={[
        "Number",
        "Description",
        "Inventory ID",
        "Inventory Description",
        "Status",
        "Qty to Produce",
        "Start Date",
        "End Date",
      ]}
      tableRows={productionOrderList.map((item) => [
        <Element data={item.ProductionNbr} dispatch={item} />,
        item.ProductionOrderDesc,
        item.InventoryID,
        item.InventoryDescription,
        item.Status,
        parseInt(item.QtytoProduce),
        moment(item.StartDate).format("MM/DD/YYYY"),
        moment(item.EndDate).format("MM/DD/YYYY"),
      ])}
      {...props}
    />
  );
}

export default ProductionOrderList;

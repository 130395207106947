import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../../presentational/Button";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Input from "../../presentational/Input";
import Gap from "../../presentational/Gap";
import moment from "moment";

export default function LaborNotesModal() {
  const {
    labor: { laborLineItems, selectedLaborIndex, laborNoteModalVisible },
    selectedDispatch,
  } = useStoreState((state) => state);

  const {
    setLaborNoteModalVisibleAction,
    setNewLaborNoteModalInputAction,
    processTimeThunk,
  } = useStoreActions((actions) => actions);

  return (
    <CustomModal
      style={{ width: 500 }}
      width="50%"
      height="50%"
      title="Notes"
      onClose={() => setLaborNoteModalVisibleAction(false)}
      isVisible={laborNoteModalVisible}
    >
      <Col>
        <Input
          multiline
          numberOfLines={4}
          value={
            laborLineItems.length > 0 &&
            laborLineItems[selectedLaborIndex]?.newNote !== null &&
            laborLineItems[selectedLaborIndex]?.newNote !== undefined
              ? laborLineItems[selectedLaborIndex]?.newNote
              : ""
          }
          onChangeText={(text) => {
            setNewLaborNoteModalInputAction({
              input: text,
              index: selectedLaborIndex,
            });
          }}
        />
      </Col>
      <Gap />
      <Row>
        <Col>
          <Button
            fullWidth
            onPress={() =>
              processTimeThunk({
                BatchNbr: {
                  value: laborLineItems[selectedLaborIndex].BatchNbr,
                },
                Date: {
                  value: moment().format("MM/DD/YYYY"),
                },
                Details: [
                  {
                    id: laborLineItems[selectedLaborIndex].AMTranNoteID,
                    note: {
                      value: laborLineItems[selectedLaborIndex].newNote,
                    },
                    LaborType: {
                      value: "Direct",
                    },
                    ProductionNbr: {
                      value: selectedDispatch,
                    },
                    EmployeeID: {
                      value: laborLineItems[selectedLaborIndex].EmployeeID,
                    },
                    LaborTime: {
                      value: laborLineItems[selectedLaborIndex].newLaborTime,
                    },
                  },
                ],
              })
            }
          >
            Update
          </Button>
        </Col>
      </Row>
    </CustomModal>
  );
}

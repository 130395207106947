import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";

function StepsList(props) {
  const { workCenterDispatch, selectedOperation } = useStoreState(
    (state) => state
  );

  const { setStepListModalVisibleAction } = useStoreActions(
    (actions) => actions
  );

  const list = workCenterDispatch.filter(
    (wcd) =>
      wcd.OperationID === selectedOperation?.OperationID &&
      (wcd.StepDesc || wcd.LineOrder)
  );

  return (
    <List
      tableHead={["Description", "Line Order"]}
      onPressCellEvents={Array(2).fill(
        () => () => setStepListModalVisibleAction(true)
      )}
      list={list}
      tableRows={list.map((wcd) => [wcd.StepDesc, wcd.LineOrder])}
      {...props}
    />
  );
}

export default StepsList;
